import React from "react";
import { Helmet } from "react-helmet";
import styles from "./additional-pages.css";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const Faq = () => {
  return (
    <>
      <Helmet>
        <title>よくある質問 | TouchWorld</title>
        <meta name="description" content="TouchWorldのよくあるご質問を記載しています。" />
      </Helmet>
      <div className={styles.body}>
        <div id="support-root"></div>
        <div className={styles.main_content}>
          <div id="terms-root" className={styles.termsRoot}>
            <div className={styles.content}>
              <h1>よくある質問</h1>
              <div className={styles.faq_block}>
                <div className={styles.question}>1. TouchWorldとはどのようなサービスですか？</div>
                <div className={styles.answer}>
                  TouchWorldは、パソコン、スマートフォン、VRヘッドマウントディスプレイ（VRHMD）からアクセス可能なブラウザベースのメタバースサービスです。ユーザーは、自分の顔を出すことなく、3D空間内で自由に移動し、他のユーザーとコミュニケーションを取ることができます。
                </div>
              </div>

              <div className={styles.faq_block}>
                <div className={styles.question}>2. TouchWorldにアクセスするにはどうすればよいですか？</div>
                <div className={styles.answer}>
                  ウェブブラウザからTouchWorldの公式サイトにアクセスすることで、特別なソフトウェアのインストールなしにご利用いただけます。
                </div>
              </div>

              <div className={styles.faq_block}>
                <div className={styles.question}>3. どのデバイスでTouchWorldを利用できますか？</div>
                <div className={styles.answer}>
                  パソコン、スマートフォン、VRヘッドマウントディスプレイ（VRHMD）でご利用いただけます。対応するブラウザやOSのバージョンについては、公式サイトの動作環境をご確認ください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>4. アカウント登録は必要ですか？</div>
                <div className={styles.answer}>
                  基本的な機能を利用する場合、アカウント登録は不要です。ただし、特定の機能やコンテンツにアクセスするためには、メールアドレスを用いたユーザー登録が必要になる場合があります。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>5. 利用料金はかかりますか？</div>
                <div className={styles.answer}>
                  本サービスの基本機能は無料でご利用いただけます。ただし、空間のカスタマイズや特別なコンテンツの利用など、有料のオプションサービスが提供される場合があります。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>6. 商用利用は可能ですか？</div>
                <div className={styles.answer}>
                  商用利用を希望される場合は、事前にリプロネクストへの申請および承認が必要です。詳しくはお問い合わせください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>7. ユーザーはどのようなコンテンツを投稿できますか？</div>
                <div className={styles.answer}>
                  ユーザーは、3Dアバター、テキスト、画像などのコンテンツを投稿・共有できます。ただし、公序良俗に反するものや、他者の権利を侵害するコンテンツの投稿は禁止されています。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>8. 個人情報の取り扱いはどうなっていますか？</div>
                <div className={styles.answer}>
                  当社は、ユーザーの個人情報を適切に保護します。取得する情報は、利用OSやブラウザ、ログデータ、ユーザー登録時のメールアドレスに限られます。詳しくはプライバシーポリシーをご覧ください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>9. 未成年でも利用できますか？</div>
                <div className={styles.answer}>
                  はい、未成年の方もご利用いただけます。ただし、親権者または法定代理人の同意を得た上でご利用ください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>
                  10. 不適切なユーザーやコンテンツを見かけた場合、どうすればよいですか？
                </div>
                <div className={styles.answer}>
                  不適切な行為やコンテンツを発見した場合は、サービス内の報告機能を使用してご連絡ください。当社が適切に対応いたします。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>11. アバターや空間のカスタマイズは可能ですか？</div>
                <div className={styles.answer}>
                  はい、ユーザーは自分のアバターや3D空間をカスタマイズできます。詳細な方法は、サービス内のガイドをご参照ください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>12. ログデータはどのように利用されますか？</div>
                <div className={styles.answer}>
                  取得したログデータは、サービスの改善やユーザー体験の向上のために利用されます。個人を特定できる形での利用は行いません。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>13. サポートやお問い合わせはどこからできますか？</div>
                <div className={styles.answer}>
                  サポートやお問い合わせは、公式サイトの「お問い合わせ」ページまたはメール（info@repronext.com）からご連絡ください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>14. サービスが正常に動作しない場合はどうすればよいですか？</div>
                <div className={styles.answer}>
                  まずは、ご利用のデバイスやブラウザが推奨環境を満たしているかご確認ください。それでも解決しない場合は、サポートまでお問い合わせください。
                </div>
              </div>
              <div className={styles.faq_block}>
                <div className={styles.question}>15. TouchWorldの最新情報はどこで確認できますか？</div>
                <div className={styles.answer}>
                  最新情報やアップデートについては、公式サイトや公式SNSアカウントで随時お知らせいたします。
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={styles.footer_lang}>
          <div className={styles.footer_lang}>
            <span>©株式会社リプロネクスト</span>
          </div>
        </footer>
      </div>
    </>
  );
};
