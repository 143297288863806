import React from "react";
import { Helmet } from "react-helmet";
import styles from "./additional-pages.css";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const Terms = () => {
  return (
    <>
      <Helmet>
        <title>利用規約 | TouchWorld</title>
        <meta name="description" content="TouchWorldの利用規約をご覧になり、ご利用ください。" />
      </Helmet>

      <div className={styles.body}>
        <div id="support-root"></div>

        <div className={styles.main_content}>
          <div id="terms-root" className={styles.termsRoot}>
            <div className={styles.content}>
              <h1>TouchWorld サービス利用規約</h1>
              <br />
              <h2>第1条（総則）</h2>
              <br />
              <p>
                本規約は、株式会社リプロネクスト（以下「当社」といいます。）が提供するメタバースサービス「TouchWorld」（以下「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用するお客様（以下「ユーザー」といいます。）は、本規約に同意した上でご利用ください。
              </p>
              <h2>第2条（サービス内容）</h2>
              <p>
                本サービスは、ブラウザを通じてパソコン、スマートフォン、VRヘッドマウントディスプレイ（VRHMD）でアクセス可能なメタバースプラットフォームです。
                ユーザーは、自分の顔を出すことなく、3D空間内で自由に移動し、他のユーザーとコミュニケーションを取ることができます。
                <br />
                本サービスでは、ログデータの取得やAIアバターによる24時間の案内等の機能を提供予定です。
              </p>
              <h2>第3条（利用登録）</h2>
              <p>
                本サービスの利用に際して、必要に応じてユーザー登録を行うことがあります。
                <br />
                ユーザー登録の際に提供されたメールアドレス等の情報は、当社のプライバシーポリシーに基づき適切に取り扱われます。
              </p>
              <h2>第4条（未成年者の利用）</h2>
              <p>未成年のユーザーは、親権者または法定代理人の同意を得た上で本サービスを利用するものとします。</p>
              <h2>第5条（利用料金）</h2>
              <p>
                本サービスの基本機能は無料で提供されます。
                <br />
                空間制作やその他の有料サービスを希望する場合は、別途当社との契約および料金の支払いが必要となります。
              </p>
              <h2>第6条（商用利用）</h2>
              <p>
                ユーザーが本サービスを商用目的で利用する場合、事前に当社の承認を得る必要があります。
                <br />
                商用利用を希望する企業または団体は、当社までご連絡の上、所定の手続きを行ってください。
              </p>
              <h2>第7条（コンテンツの投稿・共有）</h2>
              <p>
                ユーザーは、本サービス内で3Dアバター、仮想空間、テキスト、画像等のコンテンツを投稿・共有することができます。
                <br />
                投稿コンテンツに関する著作権は、ユーザーまたは正当な権利者に帰属します。
                <br />
                ユーザーは、投稿コンテンツが公序良俗に反しないこと、第三者の権利を侵害しないことを保証します。
                <br />
                当社は、必要に応じて事前の通知なく投稿コンテンツを削除することがあります。
              </p>
              <h2>第8条（コンテンツの権利）</h2>
              <p>
                当社が制作した空間やコンテンツに関する著作権等の権利は、原則として当社に帰属します。
                <br />
                ユーザーが当社と協議の上で特別な取り決めを行った場合は、この限りではありません。
              </p>
              <h2>第9条（禁止事項）</h2>
              <p>ユーザーは、本サービスの利用にあたり、以下の行為を行ってはなりません。</p>
              <p>
                法令または公序良俗に反する行為
                <br />
                他のユーザー、当社、提携企業に対する誹謗中傷、迷惑行為
                <br />
                他者の権利を侵害する行為
                <br />
                不適切なコンテンツの投稿・共有
                <br />
                当社の承認なく商用利用を行うこと
                <br />
                本サービスの運営を妨害する行為
              </p>
              <h2>第10条（利用制限およびアカウント停止）</h2>
              <p>
                当社は、ユーザーが本規約に違反した場合、事前の通知なく当該ユーザーの利用を制限またはアカウントを停止することができます。
                当社は、必要に応じて法的措置を講じる権利を有します。
              </p>
              <h2>第11条（個人情報の取り扱い）</h2>
              <p>
                当社は、ユーザーの個人情報を当社のプライバシーポリシーに従って適切に取り扱います。
                <br />
                取得する情報は、利用OS、ブラウザ、空間内での移動履歴、視点等のログデータ、およびユーザー登録時のメールアドレスに限られます。
                <br />
                ユーザーの同意なく、個人を特定できる情報を第三者に提供することはありません。
              </p>
              <h2>第12条（免責事項）</h2>
              <p>
                当社は、本サービスの提供に関して、可能な限り安定したサービスを提供するよう努めますが、その完全性、正確性、有用性等を保証するものではありません。
                ユーザーが本サービスを利用することにより生じた損害について、当社は一切の責任を負いません。
              </p>
              <h2>第13条（サービスの変更・終了）</h2>
              <p>
                当社は、事前の通知なく本サービスの内容を変更、追加、または一時的に中断することがあります。
                <br />
                本サービスを終了する場合、当社は適切な方法でユーザーに事前に通知します。
              </p>
              <h2>第14条（規約の変更）</h2>
              <p>
                当社は、本規約を必要に応じて変更することができます。規約の変更が行われた場合、当社は適切な方法でユーザーに通知します。変更後の規約は、当社が定める時点より効力を生じます。
              </p>
              <h2>第15条（準拠法および裁判管轄）</h2>
              <p>
                本規約は、日本法に準拠し解釈されます。本サービスに関連して生じた紛争については、新潟地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>
              <h2>第16条（お問い合わせ）</h2>
              <p>本サービスに関するお問い合わせは、以下の連絡先までお願いいたします。</p>
              <p>
                株式会社リプロネクスト
                <br />
                メールアドレス：{" "}
                <a className={styles.a} href="mailto:info@repronext.com">
                  info@repronext.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <footer className={styles.footer}>
          <div className={styles.footer_lang}>
            <span>©株式会社リプロネクスト</span>
          </div>
        </footer>
      </div>
    </>
  );
};
