import React from "react";
import { Helmet } from "react-helmet";
import styles from "./additional-pages.css";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>プライバシーポリシー | TouchWorld</title>
        <meta
          name="description"
          content="TouchWorldではユーザーのご利用端末からの情報を収集し、外部事業者に送信しています。外部事業者の名称・サービス名、外部事業者に送信される利用者情報の内容、送信される情報の利用目的については、以下をご確認ください。"
        />
      </Helmet>
      <body className={styles.body}>
        <div className={styles.main_content}>
          <div id="support-root"></div>
          <div id="terms-root" className={styles.termsRoot}>
            <div className={styles.content}>
              <h1>お客様ご利用端末からの情報の外部送信について</h1>
              <p>
                株式会社リプロネクスト（以下、「当社」。）は、当社が提供するブラウザメタバースサービス「TouchWorld」（以下、「本サービス」といいます。）を通じて、お客様ご利用端末からの情報を収集し、外部事業者に送信しています。
                外部事業者の名称・サービス名、外部事業者に送信される利用者情報の内容、送信される情報の利用目的については、以下をご確認ください。
              </p>

              <h2>1. 外部送信する情報</h2>
              <p>
                ①Googleアナリティクスの情報収集モジュールによる取得情報
                <br />
                ・IPアドレス
                <br />
                ・閲覧ページURL
                <br />
                ・リファラー
                <br />
                ・デバイスタイプ
                <br />
                ・オペレーティングシステム
                <br />
                ・ブラウザタイプ
                <br />
                ・利用言語
                <br />
                ・本サービス上での操作や利用履歴
                <br />
                <br />
                また、本サービスでは、「Google
                アナリティクスの広告向けの機能」を有効にしており、以下の機能を利用しています。
                <br />
                ・リマーケティング機能を含む、広告向けの各種機能
                <br />
                ・Google アナリティクスのユーザー属性とインタレストカテゴリに関するレポート
                <br />
                ・Google シグナル
              </p>
              <h2>2. 外部送信する目的</h2>
              <p>
                ①本サービス品質改善および各種分析・調査のために利用します。また、利用者を特定することが不可能な統計データを、本サービスで配信されるコンテンツの提供元企業または組織へ提供する場合があります。
              </p>
              <h2>3. 外部送信先の事業者とその目的</h2>
              <p>
                ①Google LLCおよびその完全子会社(Google)
                <br />
                Googleでは、送信された情報を分析、統計化し、ウェブサイトのパフォーマンスに関するレポートを当社に提供します。
                <br />
                また、Googleでは、これらの情報をGoogle独自のサービス・プロダクトの改善を目的として利用する場合があります。
              </p>
            </div>
          </div>
        </div>

        <footer className={styles.footer}>
          <div className={styles.footer_lang}>
            <span>©株式会社リプロネクスト</span>
          </div>
        </footer>
      </body>
    </>
  );
};
